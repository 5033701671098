import React from 'react';
import {getRates} from "../../ducks/ratesReducer";
import Rate from "./Rate";

import './rates.scss';
import {useSelector} from "react-redux";
import Title from "../util/Title.jsx";

const Rates = () => {

    const rates = useSelector(getRates)

    return (
        <div className={'rates'}>
            <Title text={`${rates[0]?.rate_usd}`} />
            {rates.map(rate => <Rate key={rate.id} {...rate}/>)}
        </div>
    );
}

export default Rates;
