import {fetchRates} from "../api/ratesApi";

export const NAME = 'rates';

export const FETCH_DATA_PENDING = `${NAME}/FETCH_DATA_PENDING`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;

export const loadRates = () => dispatch => {
    dispatch({ type: FETCH_DATA_PENDING });
    fetchRates().then(rates =>
        dispatch({
            type: FETCH_DATA_SUCCESS,
            rates,
        })
    ).catch(error =>
        dispatch({
            type: FETCH_DATA_FAILURE,
            error,
        })
    )
};

const initialState = {
    rates: [],
    isLoading: false,
    error: undefined,
};

export const randomAdd = n => n + (Math.random() * 2) - 1;

const reducer = (state = initialState, {type, error, rates}) => {
    switch(type){
        case FETCH_DATA_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rates,
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error,
            };
        default:
            return state;
    }
};

const getState = state => state[NAME];

export const getRates = state => getState(state).rates;

export default reducer;