import axios from 'axios';

const transformRatesFromWS = ({data}) => data;

export function fetchRates(){

    // const url = 'http://localhost:8080/dashboard';
    const url = 'https://kpi.oddy.no/dashboard';

    return axios.get(url)
        .then(response => {
            return transformRatesFromWS(response)
        })
        .catch(error => {
            throw new Error(error)
        })

}