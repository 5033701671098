import React from 'react';
import Counter from "../util/Counter";
import PositiveIcon from "../icons/PositiveIcon";
import NegativeIcon from "../icons/NegativeIcon";
import {DECIMAL_FORMAT} from "../../util/formatters/numbers.js";

const ATH_THRESHOLD = .98

const Rate = ({
                  id,
                  is_usd_asset,
                  rate,
                  rate_usd,
                  max_rate,
                  max_rate_usd,
                  open,
                  code,
                  selector,
                  decimals = 0,
                  header,
                  url = 'https://oddy.no',
                  ...restOfProps
              }) => {
    const changePct = (rate - open) / open
    const isAthNOK = max_rate * ATH_THRESHOLD <= rate
    const isAthUSD = max_rate_usd * ATH_THRESHOLD <= rate_usd
    const prefix = is_usd_asset ? '$' : 'kr'
    return (
        <div className={`
            rate
            ${isAthUSD && !isAthNOK ? 'is-usd-ath' : ''} 
            ${isAthNOK && !isAthUSD ? 'is-nok-ath' : ''}
            ${isAthNOK && isAthUSD ? 'is-both-ath' : ''}
        `}>
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <h5>
                    <span className='numerator'>{is_usd_asset ? 'USD' : 'NOK'}</span>
                    <span className='delimiter'>/</span>
                    <span className='denominator'>{code}</span>
                </h5>
                <Counter
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                    amount={is_usd_asset ? rate_usd : rate}
                    prefix={prefix}
                    {...restOfProps}
                />
                <div className='change'>
                    <Counter
                        prefix={changePct < 0 ? <NegativeIcon /> : <PositiveIcon />}
                        decimals={2}
                        amount={changePct * 100}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
            </a>
        </div>
    )
}

export default Rate;