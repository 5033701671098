import React from 'react';
import {useDispatch} from "react-redux";
import Timer from "../util/Timer";
import {loadRates} from "../../ducks/ratesReducer";
import Rates from "../rates/Rates";

const Dashboard = () => {
    const dispatch = useDispatch();
    return(
        <>
            <Timer
                offset={5}
                interval={5}
                instantly={true}
                action={()=>dispatch(loadRates())}
            />
            <Rates />
        </>
    );
}
;

export default Dashboard;